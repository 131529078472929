<template>
  <div class="wrapper px-4">
    <v-tabs color="primary primary-4" left>
      <v-tab>مشاريع القطاع العام</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    return-object
                    :items="allCities"
                    item-text="name"
                    label="المحافظة"
                    outlined
                    v-model="formCity.governorate_id"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    elevation="2"
                    width="80%"
                    height="56"
                    color="primary"
                    @click="fetchPublicSectorProjects"
                  >
                    بحث
                  </v-btn>
                </v-col>
                <v-col cols="1" justify="center">
                  <v-btn
                    elevation="2"
                    height="56"
                    color="primary"
                    @click="clearProjectSelectors"
                  >
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" v-show="publicSectorProjects.length != 0">
                  <download-excel
                    :fields="{
                      الاسم: 'name',
                      المحافظة: 'governorate.name',
                      العنوان: 'location',
                      البريد_الالكتروني: 'email',
                    }"
                    :data="publicSectorProjects"
                    :name="excelText"
                  >
                    <v-btn elevation="2" height="56" color="primary">
                      <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <ResizablePage v-if="this.user.type != `User`">
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headers"
                :items="publicSectorProjects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:[`item.sub_sectors`]="{ item }">
                  <p v-for="row in item.sub_sectors" :key="row.index">
                    {{ row.name }}
                  </p>
                </template>
                <template v-slot:[`item.logo`]="{ item }">
                  <v-img
                    :src="
                      'https://backend.gdid-platform.com/storage/' + item.img
                    "
                    max-width="150"
                    max-height="150"
                    class="mx-auto"
                  ></v-img>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
          <ResizablePage v-else>
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headersUser"
                :items="publicSectorProjects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >هل انت متأكد من حذف المشروع؟</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >الغاء الحذف</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >نعم</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">تعديل الشركة</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="formPublicSector" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="formPublicSector.name"
                        :rules="formRule"
                        label="الاسم"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        return-object
                        :items="allCities"
                        item-text="name"
                        label="المحافظة"
                        outlined
                        :rules="formRule"
                        v-model="formPublicSector.governorate"
                      ></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="formPublicSector.location"
                        :rules="formRule"
                        label="العنوان"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="formPublicSector.email"
                        :rules="formRule"
                        label="البريد الالكتروني"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        required
                        outlined
                        v-model="formPublicSector.logo"
                        :rules="formRule"
                        label="شعار الشركة"
                        accept=".png, .jpg, .jpeg"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                الغاء التعديل
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveEditDialog">
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>

      <v-tab>الشركات المدمجة</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    return-object
                    :items="allPublicSectorProjects"
                    item-text="name"
                    label="اسم الشركة بعد الدمج"
                    outlined
                    v-model="formPublicFilter.public_sector_id"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    return-object
                    :items="allCities"
                    item-text="name"
                    label="اسم الشركة بعد الدمج"
                    outlined
                    v-model="formPublicFilter.governorate_id"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    elevation="2"
                    width="80%"
                    height="56"
                    color="primary"
                    @click="fetchSubSectorProjects"
                  >
                    بحث
                  </v-btn>
                </v-col>
                <v-col cols="1" justify="center">
                  <v-btn
                    elevation="2"
                    height="56"
                    color="primary"
                    @click="clearProjectSelectors"
                  >
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" v-show="subSectorProjects.length != 0">
                  <download-excel
                    :fields="{
                      الاسم: 'name',
                      اسم_الشركة_بعد_الدمج: 'public_sector_name',
                      المحافظة: 'governorate_name',
                      العنوان: 'location',
                    }"
                    :data="subSectorProjects"
                    :name="excelText"
                  >
                    <v-btn elevation="2" height="56" color="primary">
                      <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <ResizablePage v-if="this.user.type != `User`">
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headersSubSectors"
                :items="subSectorProjects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItemSubSector(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItemSubSector(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:[`item.sub_sectors`]="{ item }">
                  <p v-for="row in item.sub_sectors" :key="row.index">
                    {{ row.name }}
                  </p>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
          <ResizablePage v-else>
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headersUserSubSectors"
                :items="subSectorProjects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItemSubSector(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItemSubSector(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
        </v-card>
        <v-dialog v-model="dialogDeleteSubSector" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >هل انت متأكد من حذف المشروع؟</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >الغاء الحذف</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirmSubSector"
                >نعم</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEditSubSector" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">تعديل الشركة</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="formSubSector" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="formSubSector.name"
                        :rules="formRule"
                        label="الاسم"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        return-object
                        :items="allPublicSectorProjects"
                        item-text="name"
                        label="الشركة الأم"
                        outlined
                        :rules="formRule"
                        v-model="formSubSector.public_sector"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                الغاء التعديل
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditDialogSubSector"
              >
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ResizablePage from "@/components/resizable-page.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ResizablePage,
  },
  mounted() {
    this.fetchAllCities();
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الشركات المدمجة",
          align: "center",
          sortable: false,
          value: "sub_sectors",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "البريد الالكتروني",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "شعار الشركة",
          align: "center",
          sortable: false,
          value: "logo",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الاجرائات",
          align: "center",
          sortable: false,
          value: "actions",
          class: "primary white--text ",
          width: 150,
        },
      ],
      headersUser: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الشركات المدمجة",
          align: "center",
          sortable: false,
          value: "sub_sectors",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "البريد الالكتروني",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary white--text ",
          width: 150,
        },
      ],
      headersSubSectors: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "اسم الشركة بعد الدمج",
          align: "center",
          sortable: false,
          value: "public_sector_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الاجرائات",
          align: "center",
          sortable: false,
          value: "actions",
          class: "primary white--text ",
          width: 150,
        },
      ],
      headersUserSubSectors: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "اسم الشركة بعد الدمج",
          align: "center",
          sortable: false,
          value: "public_sector_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
      ],

      publicSectorProjects: [],
      allPublicSectorProjects: [],
      subSectorProjects: [],
      allCities: [],
      formCity: {
        governorate_id: null,
      },
      formPublicFilter: {
        public_sector_id: null,
        governorate_id: null,
      },
      formPublicSector: {
        governorate: "",
        name: "",
        location: "",
        email: "",
        logo: null,
      },
      formSubSector: {
        name: "",
        public_sector: "",
      },
      dialogDelete: false,
      dialogDeleteSubSector: false,
      dialogEdit: false,
      dialogEditSubSector: false,
      selectedPublicProject: "",
      selectedSubProject: "",
      loadingDialog: false,
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      excelText: "",
    };
  },
  methods: {
    editItem(item) {
      this.dialogEdit = true;
      this.selectedPublicProject = item;
      this.formPublicSector = {
        governorate: this.selectedPublicProject.governorate,
        name: this.selectedPublicProject.name,
        location: this.selectedPublicProject.location,
        email: this.selectedPublicProject.email,
        logo: this.selectedPublicProject.img,
      };
    },
    editItemSubSector(item) {
      this.dialogEditSubSector = true;
      this.selectedSubProject = item;
      this.formSubSector = {
        name: this.selectedSubProject.name,
        public_sector: this.selectedSubProject.public_sector,
      };
      console.log(this.formSubSector);
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.dialogEditSubSector = false;
      this.selectedPublicProject = "";
      this.selectedSubProject = false;
    },
    saveEditDialog() {
      if (this.$refs.formPublicSector.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("governorate_id", this.formPublicSector.governorate.id);
        formData.append("name", this.formPublicSector.name);
        formData.append("location", this.formPublicSector.location);
        formData.append("email", this.formPublicSector.email);
        formData.append("img", this.formPublicSector.logo);
        axios
          .post(
            `auth/PublicSector/${this.selectedPublicProject.id}/update`,
            formData
          )
          .then(() => {
            this.dialogEdit = false;
            this.loadingDialog = false;
            this.selectedPublicProject = "";
            this.fetchPublicSectorProjects();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
    saveEditDialogSubSector() {
      if (this.$refs.formSubSector.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("name", this.formSubSector.name);
        formData.append(
          "public_sector_id",
          this.formSubSector.public_sector.id
        );
        axios
          .post(`auth/SubSector/${this.selectedSubProject.id}/update`, formData)
          .then(() => {
            this.dialogEditSubSector = false;
            this.loadingDialog = false;
            this.selectedSubProject = "";
            this.fetchSubSectorProjects();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedPublicProject = item;
    },
    deleteItemSubSector(item) {
      this.dialogDeleteSubSector = true;
      this.selectedSubProject = item;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedPublicProject = null;
      this.dialogDeleteSubSector = false;
      this.selectedSubProject = null;
    },
    deleteItemConfirm() {
      this.loadingDialog = true;
      axios
        .get(`auth/PublicSector/${this.selectedPublicProject.id}/delete`)
        .then(() => {
          this.dialogEditSubSector = false;
          this.selectedPublicProject = "";
          this.loadingDialog = false;
          this.fetchPublicSectorProjects();
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    deleteItemConfirmSubSector() {
      this.loadingDialog = true;
      axios
        .get(`auth/SubSector/${this.selectedSubProject.id}/delete`)
        .then(() => {
          this.dialogDeleteSubSector = false;
          this.selectedSubProject = "";
          this.loadingDialog = false;
          this.fetchSubSectorProjects();
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    fetchPublicSectorProjects() {
      this.loadingDialog = true;
      this.excelText = "مشاريع القطاع العام ";
      const formData = new FormData();
      if (this.formCity.governorate_id) {
        formData.append("governorate_id", this.formCity.governorate_id.id);
        this.excelText += ` ${this.formCity.governorate_id.name}`;
      }
      axios
        .post("PublicSector/getList", formData)
        .then((res) => {
          this.publicSectorProjects = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    fetchSubSectorProjects() {
      this.loadingDialog = true;
      this.excelText = "الشركات المدمجة ";
      const formData = new FormData();
      if (this.formPublicFilter.public_sector_id) {
        formData.append(
          "public_sector_id",
          this.formPublicFilter.public_sector_id.id
        );
        this.excelText += ` ${this.formPublicFilter.public_sector_id.name}`;
      }
      if (this.formPublicFilter.governorate_id) {
        formData.append(
          "governorate_id",
          this.formPublicFilter.governorate_id.id
        );
        this.excelText += ` ${this.formPublicFilter.governorate_id.name}`;
      }
      axios
        .post("SubSector/getList", formData)
        .then((res) => {
          this.subSectorProjects = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    fetchAllCities() {
      this.loadingDialog = true;
      axios
        .post("Governorates/getList")
        .then((res) => {
          this.allCities = res.data.items;
        })
        .catch(() => {});
      axios
        .post("PublicSector/getList")
        .then((res) => {
          this.allPublicSectorProjects = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    clearProjectSelectors() {
      this.formCity = {
        governorate_id: null,
      };
      this.formPublicFilter = {
        public_sector_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
  background: white;
}
</style>
