<template>
  <div class="wrapper px-4">
    <v-tabs color="primary primary-4" left>
      <v-tab>مشاريع القطاع المختلط</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    return-object
                    :items="allCities"
                    item-text="name"
                    label="المحافظة"
                    outlined
                    v-model="formCity.governorate_id"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    elevation="2"
                    width="80%"
                    height="56"
                    color="primary"
                    @click="fetchSharedProjects"
                  >
                    بحث
                  </v-btn>
                </v-col>
                <v-col cols="1" justify="center">
                  <v-btn
                    elevation="2"
                    height="56"
                    color="primary"
                    @click="clearProjectSelectors"
                  >
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" v-show="sharedProjects.length != 0">
                  <download-excel
                    :fields="{
                      اسم_الشركة: 'name',
                      المحافظة: 'governorate.name',
                      العنوان: 'location',
                      سنة_التأسيس: 'year_established',
                      المعامل: 'factories',
                      نسبة_مساهمة_القطاع_العام: 'public_share',
                      نسبة_مساهمة_القطاع_الخاص: 'private_share',
                      الهاتف_الرئيسي: 'phone',
                      البريد_الالكتروني: 'email',
                    }"
                    :data="sharedProjects"
                    :name="excelText"
                  >
                    <v-btn elevation="2" height="56" color="primary">
                      <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <ResizablePage v-if="this.user.type != `User`">
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headers"
                :items="sharedProjects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:[`item.public_share`]="{ item }">
                  <p>{{ item.public_share * 100 }}%</p>
                </template>
                <template v-slot:[`item.private_share`]="{ item }">
                  <p>{{ item.private_share * 100 }}%</p>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
          <ResizablePage v-else>
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headersUser"
                :items="sharedProjects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.public_share`]="{ item }">
                  <p>{{ item.public_share * 100 }}%</p>
                </template>
                <template v-slot:[`item.private_share`]="{ item }">
                  <p>{{ item.private_share * 100 }}%</p>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >هل انت متأكد من حذف المشروع؟</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >الغاء الحذف</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >نعم</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">تعديل المشروع</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.name"
                        :rules="formRule"
                        label="الاسم"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <v-select
                        :items="allCities"
                        item-text="name"
                        item-value="id"
                        label="المحافظة"
                        outlined
                        v-model="form.governorate"
                        :rules="formRule"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.location"
                        :rules="formRule"
                        label="العنوان"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.year_established"
                        :rules="formRule"
                        label="سنة التأسيس"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.factories"
                        :rules="formRule"
                        label="المعامل"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.public_share"
                        :rules="formRule"
                        label="نسبة مساهمة القطاع العام (%)"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.private_share"
                        :rules="formRule"
                        label="نسبة مساهمة القطاع الخاص (%)"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.phone"
                        :rules="formRule"
                        label="الهاتف الرئيسي"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.email"
                        :rules="formRule"
                        label="البريد الالكتروني"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                الغاء التعديل
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveEditDialog">
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ResizablePage from "@/components/resizable-page.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ResizablePage,
  },
  mounted() {
    this.fetchAllCities();
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "سنة التأسيس",
          align: "center",
          sortable: false,
          value: "year_established",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المعامل",
          align: "center",
          sortable: false,
          value: "factories",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نسبة مساهمة القطاع العام",
          align: "center",
          sortable: false,
          value: "public_share",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نسبة مساهمة القطاع الخاص",
          align: "center",
          sortable: false,
          value: "private_share",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الهاتف الرئيسي",
          align: "center",
          sortable: false,
          value: "phone",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "البريد الالكتروني",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الاجرائات",
          width: 150,
          align: "center",
          value: "actions",
          class: "primary white--text ",
        },
      ],
      headersUser: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "سنة التأسيس",
          align: "center",
          sortable: false,
          value: "year_established",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المعامل",
          align: "center",
          sortable: false,
          value: "factories",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نسبة مساهمة القطاع العام",
          align: "center",
          sortable: false,
          value: "public_share",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نسبة مساهمة القطاع الخاص",
          align: "center",
          sortable: false,
          value: "private_share",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الهاتف الرئيسي",
          align: "center",
          sortable: false,
          value: "phone",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "البريد الالكتروني",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary white--text ",
          width: 150,
        },
      ],
      sharedProjects: [],
      allCities: [],
      formCity: {
        governorate_id: null,
      },
      form: {
        governorate: "",
        name: "",
        location: "",
        year_established: "",
        factories: "",
        public_share: "",
        private_share: "",
        phone: "",
        email: "",
      },
      dialogDelete: false,
      dialogEdit: false,
      selectedSharedProject: "",
      loadingDialog: false,
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      excelText: "",
    };
  },
  methods: {
    editItem(item) {
      this.dialogEdit = true;
      this.selectedSharedProject = item;
      this.form = {
        governorate: this.selectedSharedProject.governorate.id,
        name: this.selectedSharedProject.name,
        location: this.selectedSharedProject.location,
        year_established: this.selectedSharedProject.year_established,
        factories: this.selectedSharedProject.factories,
        public_share: this.selectedSharedProject.public_share,
        private_share: this.selectedSharedProject.private_share,
        phone: this.selectedSharedProject.phone,
        email: this.selectedSharedProject.email,
      };
      console.log(this.form);
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.selectedSharedProject = "";
    },
    saveEditDialog() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        console.log(this.form);
        const formData = new FormData();
        formData.append("governorate_id", this.form.governorate);
        formData.append("name", this.form.name);
        formData.append("location", this.form.location);
        formData.append("year_established", this.form.year_established);
        formData.append("factories", this.form.factories);
        formData.append("public_share", this.form.public_share);
        formData.append("private_share", this.form.private_share);
        formData.append("phone", this.form.phone);
        formData.append("email", this.form.email);

        axios
          .post(
            `auth/SharedCompany/${this.selectedSharedProject.id}/update`,
            formData
          )
          .then(() => {
            this.dialogEdit = false;
            this.loadingDialog = false;
            this.selectedSharedProject = "";
            this.fetchSharedProjects();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedSharedProject = item;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedSharedProject = null;
    },
    deleteItemConfirm() {
      this.loadingDialog = true;
      axios
        .get(`auth/SharedCompany/${this.selectedSharedProject.id}/delete`)
        .then(() => {
          this.dialogDelete = false;
          this.selectedSharedProject = "";
          this.loadingDialog = false;
          this.fetchSharedProjects();
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    fetchSharedProjects() {
      this.loadingDialog = true;
      this.excelText = "المشاريع المختلطة ";
      const formData = new FormData();
      if (this.formCity.governorate_id) {
        formData.append("governorate_id", this.formCity.governorate_id.id);
        this.excelText += ` ${this.formCity.governorate_id.name}`;
      }
      axios
        .post("SharedCompany/getList", formData)
        .then((res) => {
          this.sharedProjects = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    fetchAllCities() {
      this.loadingDialog = true;
      axios
        .post("Governorates/getList")
        .then((res) => {
          this.allCities = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    clearProjectSelectors() {
      this.formCity = {
        governorate_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
  background: white;
}
</style>
