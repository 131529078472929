<template>
  <div class="wrapper px-4">
    <v-tabs color="primary primary-4" left>
      <v-tab>مشاريع القطاع الخاص</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <v-select
                    return-object
                    :items="allCities"
                    item-text="title"
                    label="المحافظة"
                    outlined
                    v-model="formProject.governorate_id"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-select
                    :items="allMakerTypes"
                    label="نوع الصناعة"
                    outlined
                    v-model="formProject.industry_type"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-select
                    :items="allTypes"
                    item-text="title"
                    item-value="is_complete"
                    label="نوع المشروع"
                    outlined
                    v-model="formProject.is_complete"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    elevation="2"
                    width="80%"
                    height="56"
                    color="primary"
                    @click="fetchProjects"
                  >
                    بحث
                  </v-btn>
                </v-col>
                <v-col cols="1" justify="center">
                  <v-btn
                    elevation="2"
                    height="56"
                    color="primary"
                    @click="clearProjectSelectors"
                  >
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" v-show="projects.length != 0">
                  <download-excel
                    :fields="{
                      رقم_الاجازة: 'license_num',
                      اسم_المشروع: 'project_name',
                      المحافظة: 'governorate.name',
                      المنطوق: 'foundation_license_type',
                      عدد_الطاقة_الانتاجية: 'production_capacity',
                      وحدة_القياس: 'production_unit',
                      المادة_المنتجة: 'material',
                      حالة_المشروع: 'status',
                    }"
                    :data="projects"
                    :name="excelText"
                  >
                    <v-btn elevation="2" height="56" color="primary">
                      <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <ResizablePage v-if="this.user.type != `User`">
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headers"
                :items="projects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
          <ResizablePage v-else>
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headersUser"
                :items="projects"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >هل انت متأكد من حذف المشروع</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >الغاء الحذف</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >نعم</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">تعديل المشروع</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="form.is_complete"
                        :label="`هل المشروع مكتمل؟`"
                        outlined
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.licenceNumber"
                        label="رقم  الاجازة"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.projectName"
                        label="أسم المشروع"
                        :rules="formRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        v-model="form.licenceType"
                        outlined
                        label="منطوق أجازة التأسيس"
                        :rules="formRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        v-model="form.material"
                        outlined
                        label="اسم المادة المنتجة"
                        :rules="formRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        v-model="form.productionCapacity"
                        outlined
                        label="الطاقة الانتاجية"
                        :rules="formRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        v-model="form.productionUnit"
                        outlined
                        label="وحدة الطاقة الانتاجية"
                        :rules="formRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="allCities"
                        item-text="title"
                        item-value="id"
                        label="المحافظة"
                        outlined
                        v-model="form.city"
                        :rules="formRule"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="allMakerTypes"
                        label="نوع الصناعة"
                        outlined
                        v-model="form.industryType"
                        :rules="formRule"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        required
                        outlined
                        v-model="form.status"
                        label="حالة المشروع"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                الغاء التعديل
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveEditDialog">
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ResizablePage from "@/components/resizable-page.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ResizablePage,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "رقم الاجازة",
          align: "center",
          value: "license_num",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "اسم المشروع",
          align: "center",
          value: "project_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },

        {
          text: "نوع الصناعة",
          align: "center",
          value: "industry_type",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المادة المنتجة",
          align: "center",
          value: "material",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المنطوق",
          align: "center",
          value: "foundation_license_type",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "عدد الطاقة الانتاجية",
          align: "center",
          value: "production_capacity",
          class: "primary white--text",
          width: 150,
        },
        {
          text: "وحدة القياس",
          align: "center",
          value: "production_unit",
          class: "primary white--text",
          width: 150,
        },
        {
          text: "حالة المشروع",
          align: "center",
          value: "status",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الاجرائات",
          width: 150,
          align: "center",
          value: "actions",
          class: "primary white--text ",
        },
      ],
      headersUser: [
        {
          text: "رقم الاجازة",
          align: "center",
          value: "license_num",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "اسم المشروع",
          align: "center",
          value: "project_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },

        {
          text: "نوع الصناعة",
          align: "center",
          value: "industry_type",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المادة المنتجة",
          align: "center",
          value: "material",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المنطوق",
          align: "center",
          value: "foundation_license_type",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "عدد الطاقة الانتاجية",
          align: "center",
          value: "production_capacity",
          class: "primary white--text",
          width: 150,
        },
        {
          text: "وحدة القياس",
          align: "center",
          value: "production_unit",
          class: "primary white--text",
          width: 150,
        },
        {
          text: "حالة المشروع",
          align: "center",
          value: "status",
          class: "primary white--text ",
          width: 150,
        },
      ],
      projects: [],
      allCities: [
        { title: "كركوك", id: 12 },
        { title: "كربلاء", id: 11 },
        { title: "اربيل", id: 9 },
        { title: "دهوك", id: 8 },
        { title: "ديالى", id: 7 },
        { title: "الديوانية", id: 6 },
        { title: "ذي قار", id: 5 },
        { title: "البصرة", id: 4 },
        { title: "بغداد", id: 3 },
        { title: "بابل", id: 2 },
        { title: "الانبار", id: 1 },
        { title: "المثنى", id: 14 },
        { title: "واسط", id: 19 },
        { title: "السليمانية", id: 18 },
        { title: "صلاح الدين", id: 17 },
        { title: "نينوى", id: 16 },
        { title: "النجف", id: 15 },
        { title: "ميسان", id: 13 },
      ],
      allMakerTypes: ["غذائية", "معدنية", "انشائية", "نسيجية", "كيمياوية"],
      allTypes: [
        { title: "تحت التأسيس", is_complete: 0 },
        { title: "كاملة التأسيس", is_complete: 1 },
      ],
      formProject: {
        governorate_id: null,
        industry_type: null,
        is_complete: null,
      },
      form: {
        projectName: "",
        licenceNumber: "",
        licenceType: "",
        material: "",
        productionCapacity: "",
        productionUnit: "",
        city: "",
        industryType: "",
        status: "",
        is_complete: false,
      },
      dialogDelete: false,
      dialogEdit: false,
      selectedProject: "",
      loadingDialog: false,
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      excelText: "",
    };
  },
  methods: {
    editItem(item) {
      this.dialogEdit = true;
      this.selectedProject = item;
      console.log(this.selectedProject);
      this.form = {
        projectName: this.selectedProject.project_name,
        licenceNumber: this.selectedProject.license_num,
        licenceType: this.selectedProject.foundation_license_type,
        material: this.selectedProject.material,
        productionCapacity: this.selectedProject.production_capacity,
        productionUnit: this.selectedProject.production_unit,
        city: this.selectedProject.governorate_id,
        industryType: this.selectedProject.industry_type,
        status: this.selectedProject.status,
        is_complete: this.selectedProject.is_complete,
      };
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.selectedProject = "";
    },
    saveEditDialog() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("project_name", this.form.projectName);
        formData.append("foundation_license_type", this.form.licenceType);
        formData.append("material", this.form.material);
        formData.append("governorate_id", this.form.city);
        formData.append("production_capacity", this.form.productionCapacity);
        formData.append("production_unit", this.form.productionUnit);
        formData.append("industry_type", this.form.industryType);
        formData.append("licence_number", this.form.licenceNumber);
        formData.append("status", this.form.status);

        if (this.form.is_complete) {
          formData.append("is_complete", 1);
        } else {
          formData.append("is_complete", 0);
        }
        axios
          .post(`auth/Projects/${this.selectedProject.id}/update`, formData)
          .then(() => {
            this.dialogEdit = false;
            this.loadingDialog = false;
            this.selectedProject = "";
            this.fetchProjects();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedProject = item;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedProject = null;
    },
    deleteItemConfirm() {
      this.loadingDialog = true;
      axios
        .get(`auth/Projects/${this.selectedProject.id}/delete`)
        .then((res) => {
          console.log(res);
          this.dialogDelete = false;
          this.selectedProject = "";
          this.loadingDialog = false;
          this.fetchProjects();
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    fetchProjects() {
      this.loadingDialog = true;
      const formData = new FormData();
      console.log(this.formProject.governorate_id);
      if (this.formProject.governorate_id) {
        formData.append("governorate_id", this.formProject.governorate_id.id);
        this.excelText = ` ${this.formProject.governorate_id.title}`;
      }
      if (this.formProject.industry_type) {
        formData.append("industry_type", this.formProject.industry_type);
        this.excelText += ` ${this.formProject.industry_type}`;
      }
      if (this.formProject.is_complete != null) {
        formData.append("is_complete", this.formProject.is_complete);
        if (this.formProject.is_complete) {
          this.excelText += " مكتمل التأسيس";
        } else {
          this.excelText += " تحت التأسيس";
        }
      } else {
        this.excelText += " جميع المشاريع";
      }
      axios
        .post("Projects/getList", formData)
        .then((res) => {
          this.projects = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    clearProjectSelectors() {
      this.formProject = {
        governorate_id: null,
        industry_type: null,
        is_complete: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
  background: white;
}
</style>
