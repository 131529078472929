<template>
  <v-container fluid fill-height class="container">
    <v-row justify="center">
      <v-btn
        class="mx-4 cus-btn pa-0"
        height="300"
        width="250"
        @click="btnClick(1)"
      >
        <div class="btn-layout">
          <div class="background primary">
            <v-img
              src="~@/assets/ic_shared.svg"
              class="ma-auto"
              max-width="100"
            ></v-img>
          </div>
          <div class="text">خارطة شركات القطاع المختلط</div>
        </div>
      </v-btn>

      <v-btn
        class="mx-4 cus-btn pa-0"
        height="300"
        width="250"
        @click="btnClick(2)"
      >
        <div class="btn-layout">
          <div class="background color1">
            <v-img
              src="~@/assets/ic_private.svg"
              class="ma-auto"
              max-width="100"
            ></v-img>
          </div>
          <div class="text">خارطة شركات القطاع الخاص</div>
        </div>
      </v-btn>
      <v-btn
        class="mx-4 cus-btn pa-0"
        height="300"
        width="250"
        @click="btnClick(3)"
      >
        <div class="btn-layout">
          <div class="background color2">
            <v-img
              src="~@/assets/ic_public.svg"
              class="ma-auto"
              max-width="100"
            ></v-img>
          </div>
          <div class="text">خارطة شركات القطاع العام</div>
        </div>
      </v-btn>
      <v-btn
        class="mx-4 cus-btn pa-0"
        height="300"
        width="250"
        @click="btnClick(4)"
      >
        <div class="btn-layout">
          <div class="background color3">
            <v-img
              src="~@/assets/ic_industry.svg"
              class="ma-auto"
              max-width="100"
            ></v-img>
          </div>
          <div class="text">خارطة المدن الصناعية</div>
        </div>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "MapTypes",
  methods: {
    btnClick(type) {
      if (type == 1) {
        this.$router.push("/MixedSectorMap");
      }
      if (type == 2) {
        this.$router.push("/PrivateSectorMap");
      }
      if (type == 3) {
        this.$router.push("/PublicSectorMap");
      }
      if (type == 4) {
        this.$router.push("/IndustrialCitiesMap");
      }
    },
  },
};
</script>
<style scoped>
.container {
  background: url("~@/assets/main_background.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% 100% !important;
}
.v-btn {
  height: 100% !important;
  border-radius: 11px;
}
.v-btn::before {
  background-color: transparent;
}
.v-btn:hover {
  transform: scale(1.05);
}
.btn-layout {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 3fr 1fr;
  grid-template-columns: 1fr;
}
.background {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.text {
  align-self: center;
}
</style>
