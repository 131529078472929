<template>
  <div class="wrapper px-4">
    <v-tabs color="primary primary-4" left>
      <v-tab>المدن الصناعية</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    return-object
                    :items="allCities"
                    item-text="name"
                    label="المحافظة"
                    outlined
                    v-model="formCity.governorate_id"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    elevation="2"
                    width="80%"
                    height="56"
                    color="primary"
                    @click="fetchCities"
                  >
                    بحث
                  </v-btn>
                </v-col>
                <v-col cols="1" justify="center">
                  <v-btn
                    elevation="2"
                    height="56"
                    color="primary"
                    @click="clearCitySelectors"
                  >
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" v-show="industryCities.length != 0">
                  <download-excel
                    :fields="{
                      المحافظة: 'governorate.name',
                      المساحة: 'area',
                      القيمة_التخمينية_للانشاء: 'estimated_cost',
                      المدة: 'duration',
                    }"
                    :data="industryCities"
                    :name="excelText"
                  >
                    <v-btn elevation="2" height="56" color="primary">
                      <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <ResizablePage v-if="this.user.type != `User`">
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headers"
                :items="industryCities"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
          <ResizablePage v-else>
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headersUser"
                :items="industryCities"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >هل انت متأكد من حذف المدينة الصناعية؟</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >الغاء الحذف</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >نعم</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">تعديل المدينة الصناعية</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        return-object
                        :items="allCities"
                        item-text="name"
                        label="المحافظة"
                        outlined
                        :rules="formRule"
                        v-model="form.governorate"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="form.area"
                        :rules="formRule"
                        label="المساحة"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="form.estimated_cost"
                        :rules="formRule"
                        label="القيمة التخمينية للانشاء"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        required
                        outlined
                        v-model="form.duration"
                        :rules="formRule"
                        label="المدة"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                الغاء التعديل
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveEditDialog">
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ResizablePage from "@/components/resizable-page.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ResizablePage,
  },
  mounted() {
    this.fetchAllCities();
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
        },
        {
          text: "المساحة",
          align: "center",
          sortable: false,
          value: "area",
          class: "primary white--text ",
        },
        {
          text: "القيمة التخمينية للانشاء",
          align: "center",
          sortable: false,
          value: "estimated_cost",
          class: "primary white--text ",
        },
        {
          text: "المدة",
          align: "center",
          sortable: false,
          value: "duration",
          class: "primary white--text ",
        },
        {
          text: "الاجرائات",
          width: 150,
          align: "center",
          value: "actions",
          class: "primary white--text ",
        },
      ],
      headersUser: [
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
        },
        {
          text: "المساحة",
          align: "center",
          sortable: false,
          value: "area",
          class: "primary white--text ",
        },
        {
          text: "القيمة التخمينية للانشاء",
          align: "center",
          sortable: false,
          value: "estimated_cost",
          class: "primary white--text ",
        },
        {
          text: "المدة",
          align: "center",
          sortable: false,
          value: "duration",
          class: "primary white--text ",
        },
      ],
      industryCities: [],
      allCities: [],
      formCity: {
        governorate_id: null,
      },
      form: {
        governorate: "",
        area: "",
        estimated_cost: "",
        duration: "",
      },
      dialogDelete: false,
      dialogEdit: false,
      selectedCity: "",
      loadingDialog: false,
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      excelText: "",
    };
  },
  methods: {
    editItem(item) {
      this.dialogEdit = true;
      this.selectedCity = item;
      this.form = {
        governorate: this.selectedCity.governorate,
        area: this.selectedCity.area,
        estimated_cost: this.selectedCity.estimated_cost,
        duration: this.selectedCity.duration,
      };
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.selectedCity = "";
    },
    saveEditDialog() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        console.log(this.form);
        const formData = new FormData();
        formData.append("governorate_id", this.form.governorate.id);
        formData.append("area", this.form.area);
        formData.append("estimated_cost", this.form.estimated_cost);
        formData.append("duration", this.form.duration);
        axios
          .post(`auth/Cities/${this.selectedCity.id}/update`, formData)
          .then(() => {
            this.dialogEdit = false;
            this.loadingDialog = false;
            this.selectedCity = "";
            this.fetchCities();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedCity = item;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedCity = null;
    },
    deleteItemConfirm() {
      this.loadingDialog = true;
      axios
        .get(`auth/Cities/${this.selectedCity.id}/delete`)
        .then(() => {
          this.dialogDelete = false;
          this.selectedCity = "";
          this.loadingDialog = false;
          this.fetchCities();
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    fetchCities() {
      this.loadingDialog = true;
      this.excelText = "المدن الصناعية ";
      const formData = new FormData();
      if (this.formCity.governorate_id) {
        formData.append("governorate_id", this.formCity.governorate_id.id);
        this.excelText += ` ${this.formCity.governorate_id.name}`;
      }
      axios
        .post("Cities/getList", formData)
        .then((res) => {
          this.industryCities = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    fetchAllCities() {
      this.loadingDialog = true;
      axios
        .post("Governorates/getList")
        .then((res) => {
          this.allCities = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {});
    },
    clearCitySelectors() {
      this.formCity = {
        governorate_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
  background: white;
}
</style>
