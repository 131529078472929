<template>
  <div class="wrapper px-4">
    <v-tabs color="primary primary-4" left>
      <v-tab>المشرفين</v-tab>
      <v-tab>اضافة مشرف</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <ResizablePage>
            <template v-slot:table="tableProps">
              <v-data-table
                fixed-header
                :height="tableProps.tableHeight"
                :headers="headers"
                :items="admins"
                :items-per-page="20"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </ResizablePage>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >هل انت متأكد من حذف الحساب؟</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >الغاء الحذف</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >نعم</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">تعديل حساب المسؤول</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="البريد الالكتروني"
                        v-model="selectedAdmin.email"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="الاسم"
                        v-model="selectedAdmin.name"
                        :rules="nameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="الرمز السري"
                        v-model="editForm.password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                الغاء التعديل
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveEditDialog">
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item>
        <v-card width="500" height="550" class="mx-auto mt-16" flat>
          <v-card-text class="justify-center">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                solo
                light
                label="البريد الالكتروني"
                v-model="form.email"
                :rules="emailRules"
                required
              >
              </v-text-field>
              <v-text-field
                solo
                light
                type="text"
                label="الاسم"
                v-model="form.name"
                :rules="nameRules"
                required
              >
              </v-text-field>
              <v-text-field
                solo
                light
                type="password"
                label="الرمز السري"
                v-model="form.password"
                :rules="passwordRules"
                required
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              @click="CreateAdmin"
              rounded
              color="primary"
              dark
              large
              width="240"
              >اضافة</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ResizablePage from "@/components/resizable-page.vue";

export default {
  components: {
    ResizablePage,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: "البريد الالكتروني",
          align: "center",
          value: "email",
          class: "primary white--text ",
        },
        {
          text: "الاسم",
          align: "center",
          value: "name",
          class: "primary white--text ",
        },
        {
          text: "الاجرائات",
          align: "center",
          value: "actions",
          class: "primary white--text ",
        },
      ],
      admins: [],
      editForm: {
        email: "",
        name: "",
        password: "",
      },
      form: {
        email: "",
        name: "",
        password: "",
      },
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "البريد الالكتروني مطلوب",
      ],
      passwordRules: [(v) => !!v || "الرمز السري مطلوب"],
      nameRules: [(v) => !!v || "الاسم مطلوب"],
      dialogDelete: false,
      dialogEdit: false,
      selectedAdmin: "",
      loadingDialog: false,
    };
  },
  mounted() {
    this.fetchAdmins();
  },
  methods: {
    editItem(item) {
      this.dialogEdit = true;
      this.selectedAdmin = item;
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.selectedAdmin = "";
    },
    saveEditDialog() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("email", this.selectedAdmin.email);
        formData.append("name", this.selectedAdmin.name);
        if (this.editForm.password != "") {
          formData.append("password", this.editForm.password);
        }
        axios
          .post(`auth/Users/${this.selectedAdmin.id}/update`, formData)
          .then((res) => {
            console.log(res);
            this.dialogEdit = false;
            this.loadingDialog = false;

            this.selectedAdmin = "";
            this.fetchAdmins();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedAdmin = item;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedAdmin = null;
    },
    deleteItemConfirm() {
      this.loadingDialog = true;
      axios
        .get(`auth/Users/${this.selectedAdmin.id}/delete`)
        .then((res) => {
          console.log(res);
          this.dialogDelete = false;
          this.selectedAdmin = "";
          this.fetchAdmins();
          this.loadingDialog = false;
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    fetchAdmins() {
      this.loadingDialog = true;

      const formData = new FormData();
      formData.append("filter", `[["type","=","Admin"]]`);
      formData.append("take", 100000000);
      formData.append("skip", 0);
      axios
        .post("Users/getList", formData)
        .then((res) => {
          this.loadingDialog = false;
          this.admins = res.data.items;
          console.log(res);
        })
        .catch(() => {});
    },
    CreateAdmin() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("email", this.form.email);
        formData.append("name", this.form.name);
        formData.append("password", this.form.password);
        formData.append("type", "Admin");
        axios
          .post("auth/Users/create", formData)
          .then(() => {
            this.loadingDialog = false;
            this.fetchAdmins();
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
  background: white;
}
</style>
